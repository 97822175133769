import React from 'react';
import { Helmet } from 'react-helmet-async';

interface ComponentProps {
  children?: React.ReactNode;
  prefix?: string;
  title?: string;
  description?: string;
}


const SEO = ({ prefix = '', title = '', description = '', children }: ComponentProps) => {

  //////////////////////////////////////////////////////////////////////////////////////
  // Render
  //////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>{prefix}{prefix && title && ' | '}{title}</title>
        <meta name="description" content={description || 'Monitore o seu programa de fidelidade, defina metas para sua equipe, configure pilotos automáticos e muito mais!'} />
        {children}
      </Helmet>
    </>
  )
}

export default React.memo(SEO);