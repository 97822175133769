import Routes from "@Services/Router";

import { AuthProvider } from "@Contexts/Auth";
import { OutletProvider } from "@Contexts/Outlet";
import { ParceiroProvider } from "@Contexts/Parceiro";
import { SuporteProvider } from "@Contexts/Suporte";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";

// import SEO from "@Components/SEO";
// import { Provider } from "react-redux";
// import store from "./store";

const App = () => (
  <HelmetProvider >
    <ToastContainer />
    <OutletProvider>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID} autoBoot={true}>
        <BrowserRouter>
          <AuthProvider>
            <ParceiroProvider>
              <SuporteProvider>
                <Routes />
              </SuporteProvider>
            </ParceiroProvider>
          </AuthProvider>
        </BrowserRouter>
      </IntercomProvider>
    </OutletProvider>
  </HelmetProvider>

);

export default App;
