import React from 'react';
import styles from './styles.module.scss';

interface LoadingProps {
    size?: number;
    color?: string;
    className?: string;
    centered?: boolean
}

const Loading = ({size, color, centered, className}: LoadingProps) => {

    return (
        <div className={`${styles.container} ${centered ? 'mx-auto' : ''} ${className}`} style={{width: `${size}px` || '40px', height: `${size}px` || '40px'}}>
            <div className={styles.zoom}>
                <div className={styles.skChase}>
                    <div className={styles.skChaseDot}>
                        <div style={{backgroundColor: color || '#834BE1'}} className={styles.before}/>
                    </div>
                    <div className={styles.skChaseDot}>
                        <div style={{backgroundColor: color || '#834BE1'}} className={styles.before}/>
                    </div>
                    <div className={styles.skChaseDot}>
                        <div style={{backgroundColor: color || '#834BE1'}} className={styles.before}/>
                    </div>
                    <div className={styles.skChaseDot}>
                        <div style={{backgroundColor: color || '#834BE1'}} className={styles.before}/>
                    </div>
                    <div className={styles.skChaseDot}>
                        <div style={{backgroundColor: color || '#834BE1'}} className={styles.before}/>
                    </div>
                    <div className={styles.skChaseDot}>
                        <div style={{backgroundColor: color || '#834BE1'}} className={styles.before}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loading;
