import Cookies from 'js-cookie'

const defaultOptions = {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    // sameSite: 'lax',
    secure: true,
    path: '/'
}

export const getCookie = (name, defaultValue, options) => {
    const cookie = Cookies.get(name)
    if (cookie) return cookie
    if (defaultValue) {
        Cookies.set(name, defaultValue, {...defaultOptions, ...options})
        return defaultValue
    }
    return false;
}

export const setCookie = (name, value, options) => Cookies.set(name, value, {...defaultOptions, ...options});
export const removeCookie = (name, options) => Cookies.remove(name, {...defaultOptions, ...options});
