import React from 'react';
import styles from './styles.module.scss';

const FzAlert = (props) => {
  const [show, setShow] = React.useState(true)
  return ( 
    <>
      {show && (
        <div className={`d-flex align-items-center ${styles.alert} ${props.className ? props.className : ''} ${props.icon ? styles.withIcon : ''}`}>
          {!props.fixed && <button id={props.idClose} className={`${props.classClose} close ${styles.close}`} onClick={() => setShow(false)}/>}
          {props.icon && <div className={styles.icon}><i className={`alert-icon fa ${props.icon} text-yellow me-auto fs-30`}></i></div>}
          <div className="d-block w-100 mx-auto">
            {props.children}
          </div>
        </div>
      )}
    </>
  )
}

export default FzAlert;