const RoutesCalculadoraDemanda = [
  {
    path: "demanda",
    dashboard: "Demanda",
    prefix: 'Demanda Fidelizi',
    title: "Carregando Demanda",
    children: [
      {
        path: "inicio",
        component: "/Inicio",
        title: "Início"
      },
      {
        path: "ramo-atividade",
        component: "/Ramo",
        title: "Ramo"
      },
      {
        path: "lojas",
        component: "/Lojas",
        title: "Lojas"
      },
      {
        path: "funcionarios",
        component: "/Funcionarios",
        title: "Funcionários"
      },
      {
        path: "meu-negocio",
        component: "/Detalhes",
        title: "Meu Negócio"
      },
      {
        path: "vendas",
        component: "/Vendas",
        title: "Vendas"
      },
      {
        path: "gastos",
        component: "/Gastos",
        title: "Gastos"
      },
      {
        path: "perfil-compras",
        component: "/PerfilCompras",
        title: "Perfil de Compras"
      },
      {
        path: "motivacao",
        component: "/Motivacao",
        title: "Motivação"
      },
      {
        path: "problemas",
        component: "/Problemas",
        title: "Problemas"
      },
      {
        path: "resultados",
        component: "/Resultados",
        title: "Resultados"
      },
      {
        path: "como-conheceu",
        component: "/Referencia",
        title: "Como Conheceu"
      },
      {
        path: "experiencia",
        component: "/Experiencia",
        title: "Experiência"
      },
      {
        path: "implementacao",
        component: "/Implementacao",
        title: "Implementação"
      },
      {
        path: "decisao",
        component: "/Decisao",
        title: "Decisão"
      }
    ]
  },
];

export default RoutesCalculadoraDemanda;