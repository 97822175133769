const RoutesCalculadoraContratacao = [
  {
    path: "contratacao",
    dashboard: "Contratacao",
    prefix: 'Contratacao Fidelizi',
    title: "",
    children: [
      {
        path: "",
        redirect: "minha-demanda",
      },
      {
        path: "minha-demanda",
        component: "/Resumo",
        title: "Minha Demanda",
        description: "Conheça tudo que o Fidelizi oferece e revise as informações fornecidas por você no nosso formulário de demanda.",
        menu: {
          order: 1,
          icon: "fa fa-file-signature",
          name: "Minha Demanda",
        },
      },
      {
        path: "simulador",
        component: '/Simulador/Steps',
        abstract: true,
        title: 'Simulações',
        description: 'Faça uma simulação e veja como funcionaria o programa de fidelidade ideal para o seu negócio.',
        menu: {
          order: 2,
          icon: "fa fa-gamepad",
          name: "Simulações",
        },
        children: [
          {
            index: true,
            path: "",
            component: "/Lista",
            title: "Simulação do programa de fidelidade"
          },
          {
            path: "inicio",
            component: "/Inicio",
            title: "Início",
          },
          {
            path: "gastos",
            component: "/Gastos",
            title: "Simulação → Gasto individual do cliente",
          },
          {
            path: "gastos/editar/:edit",
            component: "/Gastos",
            title: "Simulação → Gasto individual do cliente",
          },
          {
            path: "frequencia",
            component: "/Frequencia",
            title: "Simulação → Frequência dos clientes",
          },
          {
            path: "frequencia/editar/:edit",
            component: "/Frequencia",
            title: "Simulação → Frequência dos clientes",
          },
          {
            path: "fluxo",
            component: "/Fluxo",
            title: "Simulação → Fluxo operacional",
          },
          {
            path: "fluxo/editar/:edit",
            component: "/Fluxo",
            title: "Simulação → Fluxo operacional",
          },
          {
            path: "prazo",
            component: "/Prazo",
            title: "Simulação → Prazo de validade dos pontos",
          },
          {
            path: "prazo/editar/:edit",
            component: "/Prazo",
            title: "Simulação → Prazo de validade dos pontos",
          },
          {
            path: "programa",
            component: "/Programa",
            title: "Simulação → Regra do programa",
          },
          {
            path: "programa/editar/:edit",
            component: "/Programa",
            title: "Simulação → Regra do programa",
          },
          {
            path: "meta",
            component: "/Meta",
            title: "Simulação → Prêmios do meu programa",
          },
          {
            path: "meta/editar/:edit",
            component: "/Meta",
            title: "Simulação → Prêmios do meu programa",
          },
          {
            path: "resumo/:idSimulacao",
            component: "/Resumo",
            title: "Simulação → Resumo e detalhes do meu programa",
            description: "Abaixo é uma projeção de como seria o seu programa de fidelidade com base nos dados informados. Caso deseje usar esses mesmos parâmetros em sua implementação, basta informar o nosso time"
          }
        ]
      },
      {
        path: "empresas",
        abstract: true,
        title: "Empresas",
        menu: {
          order: 3,
          icon: "fa fa-city",
          name: "Empresas"
        },
        children: [
          {
            path: "",
            redirect: "lista",
            title: '',
          },
          {
            index: true,
            path: "lista",
            component: "/Empresas",
            title: "Dados do meu negócio",
            description: 'Para que possamos prosseguir com a contratação, precisamos que você preencha os dados da sua empresa. Caso precise, peça ajuda ao consultor que estiver te atendendo.',
          },
          {
            path: 'cadastro',
            component: "/Empresas/Cadastro",
            title: "Dados do meu negócio → Cadastro",
            description: 'Para que possamos prosseguir com a contratação, precisamos que você preencha os dados da sua empresa. Caso precise, peça ajuda ao consultor que estiver te atendendo.',
          },
          {
            path: "editar/:idEmpresa",
            component: "/Empresas/Cadastro",
            title: "Dados do meu negócio → Editar",
            description: 'Para que possamos prosseguir com a contratação, precisamos que você preencha os dados da sua empresa. Caso precise, peça ajuda ao consultor que estiver te atendendo.',
          }
        ]
      },
    ]
  },
];

export default RoutesCalculadoraContratacao;
