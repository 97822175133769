import Loading from '@Components/Loaders/Loading';
import LogoF from '@Components/Layout/LogoF';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  color?: string;
}
  
const FLoading = ({className, color = styles['purple-4']}:Props) => {
  
  return (<>
    <div className={`${className || ''} ${styles.logo}`}>
      <LogoF styles={styles}/>
      <Loading size={112} color={color}/>
    </div>
  </>)
}
  
export default FLoading;