
interface Props {
  styles?: any;
  className?: string;
}

const LogoF = ({ styles, className }: Props) => {

  return (
    <>
      <svg className={className} width="104" height="124" viewBox="0 0 104 124" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M52.5502 2.06658C49.6664 0.635872 46.4621 0 42.2965 0C23.8719 0 12.8171 10.9688 12.8171 29.568V42.4444H9.29242C4.00535 42.4444 0 46.2596 0 51.5055C0 56.7515 4.00535 60.5667 9.29242 60.5667H12.8171V112.072C12.8171 118.272 17.4633 123.041 23.7117 123.041C29.7998 123.041 34.446 118.431 34.446 112.072V60.5667H44.3793C49.6664 60.5667 53.5115 56.7515 53.5115 51.5055C53.5115 46.2596 49.6664 42.4444 44.3793 42.4444H34.446V31.4756C34.446 23.6862 38.6116 19.2351 45.9815 19.2351C47.103 19.2351 47.904 19.3941 48.5449 19.3941C54.3126 19.3941 57.9975 15.8968 57.9975 10.6508C57.9975 6.67664 55.9147 3.49729 52.5502 2.06658Z" fill="#3A3A3A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M71.9343 2.29176L72.0073 2L72.0802 2.29176C75.1445 20.0894 85.4561 30.3256 103.015 33C85.4561 35.6744 75.1449 45.9044 72.0802 63.69L72.0073 64L71.9343 63.69C68.8825 45.9044 58.5709 35.6744 41 33C58.5709 30.3132 68.8821 20.0774 71.9343 2.29176Z" fill="#9300D9" />
      </svg>

    </>
  )
}

export default LogoF;