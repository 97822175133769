import SweetAlert from "sweetalert2";
import styles from "./styles.module.scss"

const Swal = SweetAlert.mixin({
  customClass: {
    confirmButton: 'btn btn-bg-purple me-2',
    cancelButton: 'btn btn-border-purple',
    title: 'h5 headline',
    popup: styles.popup,
    // icon : '',
    // htmlContainer : '',
  },
  buttonsStyling: false,
  showClass: {
    popup: 'animate fadeInDown'
  },
  hideClass: {
    popup: 'animate fadeOutUp'
  }
});

export default Object.assign(Swal, {
  
})