import AnimatedSquares from '@Components/Layout/AnimatedSquares';

import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import SEO from '@Components/SEO';

const Page404 = (props) => {

  return (
    <>
      <SEO prefix='Meu Fidelizi' title={'Não Encontrado'} />
      <AnimatedSquares/>
      <div className={`${styles.container} ${props.fullHeight === true ? styles.fullHeight : ''}`}>
        <div className="d-block text-center">
          <img
            src="/assets/images/mago_notfound.png"
            alt="Fidelizi - Programa de Fidelidade"
            width={320}
            height={426}
            className={styles.personagem}
          />
          {/* <div className={styles.block404}>404</div> */}
          <h1>Página não encontrada!</h1>
          <p>A página que você procura não foi encontrada ou não existe mais.</p>
          <Link to="/" className="btn btn-bg-purple-3">Voltar para a página inicial</Link>
        </div>
      </div>
    </>
  )
}

export default Page404;