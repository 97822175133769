import React from 'react';
import ReactDOM from "react-dom/client";
import '@Styles/global.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { clarity } from 'react-microsoft-clarity';

if (process.env.REACT_APP_ENV === "production") {
    console.log = () => { };
    Sentry.init({
        dsn: "https://1a6ab4afde4f4a46a9a40266ca76c942@o52779.ingest.sentry.io/6382950",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
if (process.env.REACT_APP_ENV !== "local") {
    clarity.init(process.env.REACT_APP_MICROSOFT_CLARITY_ID + '');
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
