
import { Route, Routes } from "react-router-dom";

import Middlewares from "@Middlewares/index";
import BakeRoutes from "./Components/BakeRoutes";

import RoutesPublicas from "@Routes/Publicas";
import RoutesRedePadrao from "@Routes/Rede/Padrao";

import RoutesCalculadoraContratacao from "@Routes/Calculadora/Contratacao";
import RoutesCalculadoraDemanda from "@Routes/Calculadora/Demanda";

import RoutesParceiroPadrao from "@Routes/Parceiro/RoutesParceiroPadrao";
import RoutesParceiroPlanoGame from "@Routes/Parceiro/RoutesParceiroPlanoGame";
import RoutesParceiroRedirect from "@Routes/Parceiro/RoutesParceiroRedirect";

const Router = () => {

  //////////////////////////////////////////////////////////////////////////////////////
  // render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (

    //////////////////////////////////////////////////////////////////////////////////////
    // Router 6.11
    // Aqui é onde cada dashboard deve ser criado, manual mesmo por motivos de sim :)
    //////////////////////////////////////////////////////////////////////////////////////
    <Routes>
      {BakeRoutes({ routes: RoutesPublicas, componentsFolder: "publico" })}

      {/* Demanda */}
      {BakeRoutes({ routes: RoutesCalculadoraDemanda, componentsFolder: "calculadora/Demanda" })}

      <Route element={<Middlewares use={['GetUser']} />} >

        {/* Parceiro */}
        <Route element={<Middlewares use={['IsValidParceiroID', 'ParceiroInRede', 'GetParceiro', 'RedirectPlano']} />} >
          {BakeRoutes({ routes: RoutesParceiroRedirect, componentsFolder: "parceiro" })}
          {BakeRoutes({ routes: RoutesParceiroPlanoGame, componentsFolder: "parceiro" })}
          {BakeRoutes({ routes: RoutesParceiroPadrao, componentsFolder: "parceiro" })}
        </Route>

        {/* Rede */}
        {BakeRoutes({ routes: RoutesRedePadrao, componentsFolder: "rede" })}

        {/* Calculadora */}
        {BakeRoutes({ routes: RoutesCalculadoraContratacao, componentsFolder: "calculadora/Contratacao" })}
      </Route>


    </Routes>
  )
}

export default Router;