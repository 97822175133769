import React, { createContext, useContext, useMemo } from 'react';
import { useAuth } from '@Contexts/Auth';
// Context
const ParceiroContext = createContext({});

export const useParceiro = () => useContext(ParceiroContext);

export const ParceiroProvider = ({ children }) => {
  const { parceiro: Parceiro, SetParceiro } = useAuth();

  // Memoize the context value using useMemo
  const contextValue = useMemo(() => ({ Parceiro, SetParceiro }), [Parceiro, SetParceiro]);

  return (
    <ParceiroContext.Provider value={contextValue}>
      {children}
    </ParceiroContext.Provider>
  );
};