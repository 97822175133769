import { parseISO, format, differenceInCalendarDays, parse, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
//////////////////////////////////////////////////////////////////////////////////////
// Validators
//////////////////////////////////////////////////////////////////////////////////////
export const validarEmail = (email, details) => {
    if (!email) return false;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(email);
    if (!details) return isValid;

    return {
        success: isValid,
        message: isValid
            ? 'E-Mail Válido'
            : 'E-Mail Inválido'
    }
}
export const validarPassword = (password, details) => {
    var regex = [];
    let level = 0;
    let strength = '';
    regex.push("[a-z]");  //For Lowercase Alphabet
    regex.push("[A-Z]"); //For Uppercase Alphabet
    regex.push("[0-9]");  //For Numeric Digits
    regex.push("[$@$!%*#?&]"); //For Special Characters

    password = password.replace(/\s/g, '');

    // Se o tamanho for inferior a 8 caracteres
    if (password.length < 8) {
        if (!details) return false;
        return {
            success: false,
            message: 'A senha deve possuir pelo menos 8 caracteres.',
            level: 0,
        }
    }

    // Verifica se a senha contem cada regra especial (atÃ© 4 pontos)
    for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
            level++;
        }
    }

    // Verifica se a senha tem um tamanho decente (desconta atÃ© 3 pontos)
    if (password.length <= 7) { level--; }
    if (password.length <= 12) { level--; }
    if (password.length <= 15) { level--; }

    // Se a senha do cara for ruim, mesmo assim tem que ser fraca
    if (level < 1) { level = 1; }

    if (!details) return true;

    if (level === 1)
        strength = 'Senha Fraca.';
    if (level === 2)
        strength = 'Senha Boa.';
    if (level === 3)
        strength = 'Senha Forte.';
    if (level === 4)
        strength = 'Senha Perfeita.';


    return {
        success: true,
        message: strength,
        level: level,
    }
}
export const validarCPF = (cpf) => {
    if (!cpf) return false;
    let sum = 0;
    let rest = 0;

    if (!cpf) return;

    let cleanCPF = cpf.replace(/\D/g, '');

    for (let i = 1; i <= 9; i++) sum = sum + parseInt(cleanCPF.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) rest = 0;
    if (rest !== parseInt(cleanCPF.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(cleanCPF.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) rest = 0;
    if (rest !== parseInt(cleanCPF.substring(10, 11))) return false;

    return true;
}
export const validarNomeCompleto = (nome) => {
    if (!nome) return false;
    return nome.split(' ').length > 1;
}
export const validarCelular = (celular) => {
    if (!celular) return false;
    const regEx = /^\+[1-9]\d{12,14}$/;
    const clearNumber = celular.replace(/\D/g, '');

    return regEx.test('+' + clearNumber);
}
export const validarData = (data) => {
    if (!data) return false;
    return isValid(parse(data, 'dd/MM/yyyy', new Date())) || isValid(parse(data, 'yyyy-MM-dd', new Date()));
}
export const validarSexo = (sexo) => {
    if (!sexo) return false;
    return ['M', 'm', 'F', 'f', 'o', 'O', 'na', 'NA'].indexOf(sexo) > -1;
}

//////////////////////////////////////////////////////////////////////////////////////
// Formatters
//////////////////////////////////////////////////////////////////////////////////////
export const Plano = (slug) => {
    const plano = {
        'starter': "STARTER",
        'pro': "PLUS",
        'smart': "SMART",
        'premium': "PREMIUM",
        'advanced': "ADVANCED",
        'basic': "BASIC",
        'pro-2': "PRO",
        'elite': "ELITE",
        'essential': "ESSENTIAL",
        'cielo-lite': "CIELO LITE",
        'cielo-pro': "CIELO PRO",
        'cielo-elite': "CIELO ELITE",
        'prime': "PRIME",
        'multi-pontos-conversao': 'Multi-prêmios por Pontos',
        'multi-pontos': 'Multi-prêmios por Pontos',
        'multi-checkin': 'Multi-prêmios por Check-in',
        'cashback': 'Cashback',
        'game': 'Fidelizi Fun',
    }

    return plano[slug];
}
export const formatDate = (date, formato, formatoOriginal) => {
    return format(parse(date, formatoOriginal || 'yyyy-MM-dd', new Date()), formato || 'dd/MM/yyyy');
}
export const DateFromString = (value, formato) => {
    if (typeof value?.getMonth === 'function') {
        return value
    }
    if (typeof value === 'string') {
        let parsed = [
            parse(value, "yyyy-MM-dd HH:mm:ss", new Date()),
            parse(value, "yyyy-MM-dd", new Date()),
            parse(value, "dd/MM/yyyy", new Date()),
            parse(value, "dd/MM/yyyy HH:mm:ss", new Date()),
        ]
        if (isValid(parsed[0])) { return !formato ? parsed[0] : format(parsed[0], formato, {locale: ptBR}) }
        if (isValid(parsed[1])) { return !formato ? parsed[1] : format(parsed[1], formato, {locale: ptBR}) }
        if (isValid(parsed[2])) { return !formato ? parsed[2] : format(parsed[2], formato, {locale: ptBR}) }
        if (isValid(parsed[3])) { return !formato ? parsed[3] : format(parsed[3], formato, {locale: ptBR}) }
    }

    return undefined;
}
export const expiraEm = (date, numeric) => {
    let dateToCheck = isValid(date) ? date : parseISO(date);
    let diff = differenceInCalendarDays(dateToCheck, new Date());
    if (numeric) return diff;

    if (diff > -1) {
        if (diff === 0) return 'Hoje';
        if (diff === 1) return 'Amanha';
        if (diff > 1) return `${diff} dias`;
    } else {
        return 'Expirado';
    }
}
export const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(value));
}


//////////////////////////////////////////////////////////////////////////////////////
// Misc
//////////////////////////////////////////////////////////////////////////////////////
export const calcularVariacaoPercentual = function (valorAtual, valorAnterior) {
    let data = {};

    if (valorAnterior > 0) {
        data.valor = (((valorAtual / valorAnterior) - 1) * 100).toFixed(1);
        data.variacao = (data.valor >= 0) ? 'up' : 'down';
    } else if (valorAtual === 0 && valorAnterior === 0) {
        data.valor = 0;
        data.variacao = 'up';
    } else {
        data.valor = 100;
        data.variacao = 'up';
    }

    return data;
}
export const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n) && Math.sign(n) >= 0;
}
export const getPosition = (subString, string, index) => {
    return string.split(subString, index).join(subString).length;
}
/**
 * @deprecated
 */
export const isValidDate = (dateValue) => {
    if (!dateValue) return false;

    let date = dateValue;

    if (typeof dateValue === 'string') {
        date = new Date(dateValue)
    }

    return (date instanceof Date && !isNaN(Number(date)));
}
export const calcularCashback = function (valor, cartelaModelo) {
    let percentual = parseFloat(cartelaModelo.percentual_cashback) / 100
    let min = parseFloat(cartelaModelo.valor_minimo_cashback);
    let max = parseFloat(cartelaModelo.valor_maximo_cashback_ganho);
    let val = parseFloat(valor);
    let calculado = val * percentual
    if (val < min) return 0;
    if (max > 0 && calculado > max) return max;
    return calculado;
}

/**
 * Substitui palavras de uma string baseado em um array contendo objetos de key, value
 * Substitui o key pelo value
 * {
 *   key: 'foo',
 *   value: 'bar',
 * }
 * 'foo bar' => 'bar bar'
 * @param {string} str
 * @param {array} arr
 * @returns
 */
export const replaceBulk = (str, arr, prefix = '', sufix = '') => {
    let i, regex = [], map = {};

    let findArray    = arr.map((item) => item.key);
    let replaceArray = arr.map((item) => item.value);

    for (i = 0; i < findArray.length; i++) {
        regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, '\\$1'));
        map[findArray[i]] = replaceArray[i];
    }

    regex = regex.join('|');

    const regexPattern = `${prefix}(${regex})${sufix}`;
    const replaceRegex = new RegExp(regexPattern, 'g');

    str = str.replace(replaceRegex, function (matched, group) {
        return map[group] ?? matched;
    });

    return str;
}

export const phoneMask = (value) => {
    return value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($2")
        .replace(/(\d{2})(\d)/, "$1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2")
}


export const toggleFromArray = (array, item) => {
    const index = array.indexOf(item);
    let newArray = [];
    if (index > -1) {
        newArray = [...array].filter((i) => i !== item);
    } else {
        newArray = [...array, item];
    }

    return newArray;
}

export const cartelaModelo = (parceiro, valor) => {
    const tipoPrograma = (parceiro.promocao_atual.odometro_fixo === 1) ? 'checkin' : parceiro.promocao_atual.tipo;
    const isConversaoDinheiro = !!parceiro.promocao_atual.converter_ponto_dinheiro;
    const conversaoDinheiro = parceiro.promocao_atual.conversao_dinheiro;
    const conversaoPonto = parceiro.promocao_atual.conversao_ponto;
    const travasSeguranca = {
        min: parceiro.promocao_atual.pontos_min,
        max: parceiro.promocao_atual.pontos_max,
        interacoes: parceiro.promocao_atual.interacoes_dia,
        intervalo: parceiro.minutos_entre_pontos
    }

    let pontuacao = 0;
    // Programa Cashback
    if (tipoPrograma === 'cashback') {
        let min = parseFloat(parceiro.promocao_atual.valor_minimo_cashback);
        let max = parseFloat(parceiro.promocao_atual.valor_maximo_cashback_ganho);
        let val = parseFloat(valor);
        if (val < min) return 0;
        if (max > 0 && val > max) return max;
        pontuacao = valor * parseFloat(parceiro.promocao_atual.percentual_cashback) / 100;
    }

    // Programa checkin
    if (tipoPrograma === 'checkin') {
        pontuacao = parseInt(parceiro.promocao_atual.odometro_fixo_pontos || 1);
    }

    // Odometro com conversÃ£o de dinheiro em pontos
    if (tipoPrograma === 'odometro' && isConversaoDinheiro) {
        pontuacao = Math.floor(valor / parseFloat(conversaoDinheiro)) * parseFloat(conversaoPonto);
    }

    // Odometro sem conversÃ£o de dinheiro em pontos
    if (tipoPrograma === 'odometro' && !isConversaoDinheiro) {pontuacao = valor};


    if ((tipoPrograma === 'odometro'  && isConversaoDinheiro) || tipoPrograma === 'checkin') {
        let pontuacaoEmDinheiro = Math.ceil((valor / conversaoPonto)) * conversaoDinheiro;
        if (tipoPrograma === 'checkin')  pontuacaoEmDinheiro = Math.ceil((valor / conversaoPonto)) * parceiro.ticket_medio;
        return ({ pontuacao, pontuacaoEmDinheiro, tipoPrograma, isConversaoDinheiro, travasSeguranca })
    }



    return ({ pontuacao, tipoPrograma, isConversaoDinheiro, travasSeguranca });
}

export const ListaPlanos = {
    todos     : ['starter', 'pro', 'smart', 'basic', 'pro-2', 'elite', 'prime', 'essential', 'cielo-lite', 'cielo-pro', 'cielo-elite', 'game', 'magic', 'magic-plus'],
    fidelidade: ['starter', 'pro', 'smart', 'basic', 'pro-2', 'elite', 'prime', 'essential', 'magic', 'magic-plus'],
    game      : ['game'],
    cielo     : ['cielo-lite', 'cielo-pro', 'cielo-elite'],
}

export const slugify = (text) => {
    text = text.toString().toLowerCase();

    const sets = [
        { to: 'a', from: '[àáâãäåæāăąấầẩẫậắằẳẵặÀÁÂÃÄÅÆĀĂĄÁÂÃÄÅÆÄẠÀÁÂÃÄÅÆ]' },
        { to: 'c', from: '[çćčÇĆČ]' },
        { to: 'd', from: '[ðďđÐĎĐ]' },
        { to: 'e', from: '[èéêëēĕėęěếềểễệÈÉÊËĒĔĖĘÊËẸ]' },
        { to: 'g', from: '[ĝğġģĜĞĠĢ]' },
        { to: 'h', from: '[ĥħĤĦ]' },
        { to: 'i', from: '[ìíîïīĩĭįỉịÌÍÎÏĨĬĮİỈỊ]' },
        { to: 'j', from: '[ĵĴ]' },
        { to: 'ij', from: '[ĳĲ]' },
        { to: 'k', from: '[ķĸĶ]' },
        { to: 'l', from: '[ĺļľłĹĽŁ]' },
        { to: 'm', from: '[ḿ]' },
        { to: 'n', from: '[ñńňņŃŇŅ]' },
        { to: 'o', from: '[òóôõöøōŏőồốổỗộȯȱỏọỒÓÔÕÖØŌŎŐỔỖỖỘỌ]' },
        { to: 'oe', from: '[œŒ]' },
        { to: 'p', from: '[ṕ]' },
        { to: 'r', from: '[ŕřŗŔŘŖ]' },
        { to: 's', from: '[ßśŝşšŚŜŞŠ]' },
        { to: 't', from: '[ţťŧŢŤŦ]' },
        { to: 'u', from: '[ùúûüũūŭůűủụÙÚÛÜŨŪŬŮŰỦỤÛ]' },
        { to: 'w', from: '[ẁẃẅẂẄẄẆẀ]' },
        { to: 'x', from: '[ẍ]' },
        { to: 'y', from: '[ýÿŷỳỵỹỲÝŸŶ]' },
        { to: 'z', from: '[źžżŹŽŻ]' },
        { to: '-', from: '[·/_,:;\']' }
    ];

    sets.forEach(set => {
        text = text
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')
            .replace(new RegExp(set.from, 'gi'), set.to)
    });

    return text.replace(/[^-a-z0-9]+/g, ''); // Remove all non-word chars
}

export const porcentagem = (value1, value2, toFixed = 2) => {
    if (value1 === 0 || value1 === '0' || value2 === 0 || value2 === '0' || !value1 || !value2) return 0;

    const result = ((value1 / value2) * 100).toFixed(toFixed);

    return result;
}

export const calculatePercentageFromRatio = (ratio) => {
    return (ratio * 100).toFixed(2)
}