import React from 'react';
import styles from './style.module.scss';
import colors from "@Styles/_variables.scss"

export default function AnimatedSquares(props) {
  return (
    <>
    <div id="AnimatedSquares" className={`${styles.area} ${props.className}`}>
      <ul className={styles.circles}>
        <li style={{background: props.color || colors[props.fzColor || 'yellow-3']}}></li>
        <li style={{background: props.color || colors[props.fzColor || 'yellow-3']}}></li>
        <li style={{background: props.color || colors[props.fzColor || 'yellow-3']}}></li>
        <li style={{background: props.color || colors[props.fzColor || 'yellow-3']}}></li>
        <li style={{background: props.color || colors[props.fzColor || 'yellow-3']}}></li>
        <li style={{background: props.color || colors[props.fzColor || 'yellow-3']}}></li>
        <li style={{background: props.color || colors[props.fzColor || 'yellow-3']}}></li>
        <li style={{background: props.color || colors[props.fzColor || 'yellow-3']}}></li>
        <li style={{background: props.color || colors[props.fzColor || 'yellow-3']}}></li>
        <li style={{background: props.color || colors[props.fzColor || 'yellow-3']}}></li>
      </ul>
    </div>
    </>
  );
}
