const RoutesParceiroRedirect = [
  {
    path: "dashboard",
    dashboard: "Parceiro/Redirect",
    prefix: "Meu Fidelizi",
    title: "Dashboard",
    children: [
      {
        path: ":idParceiro",
        abstract: true,
        children: [
          {
            path: "*",
            abstract: true,
          },
        ],
      },
    ]
  }
];

export default RoutesParceiroRedirect;
