import React from 'react';
import { useAuth } from '@Contexts/Auth';
import { Navigate, useParams } from 'react-router-dom';

interface ComponentProps {
  children?: React.ReactNode;
}

/**
 * Checa se o idParceiro acessado está presente na rede de parceiros que o 
 * usuário pode acessar
 */
const ParceiroInRede = ({children}: ComponentProps) => {
  //////////////////////////////////////////////////////////////////////////////////////
  // Hooks
  //////////////////////////////////////////////////////////////////////////////////////
  const { idParceiro } = useParams();
  const { isSuperAdmin, rede } = useAuth();

  //////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////
  const parceirosID = rede.map(item => item.id_parceiro);

  //////////////////////////////////////////////////////////////////////////////////////
  // Render
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {(!parceirosID.includes(Number(idParceiro)) && !isSuperAdmin) && <Navigate to="/rede" replace={true}/>}
    {(parceirosID.includes(Number(idParceiro)) || isSuperAdmin) && children}
  </>)
}

export default React.memo(Object.assign(ParceiroInRede, {displayName: 'ParceiroInRede'}), () => true);