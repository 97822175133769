import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
// Context
const SuporteContext = React.createContext({});

export const useSuporte = () => React.useContext(SuporteContext);

export const SuporteProvider = ({ children }) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const {
    showNewMessage
  } = useIntercom();

  const handleSupportParams = () => {
    /** Abre automáticamente a janela do Intercom com mensagem de ajuda */
    if ('suporte' in params) {
      // Remove da url pra não ficar abrindo sempre o suporte.
      let tempParams = { ...params };
      delete tempParams.suporte;
      showNewMessage((params.suporte !== '') ? params.suporte : "Olá, preciso de suporte com o Fidelizi \n\n");
      setSearchParams(tempParams);
    }
  }

  React.useEffect(() => {
    handleSupportParams();
  }, [location])

  return (<>
    <SuporteContext.Provider value={{}}>
      {children}
    </SuporteContext.Provider>
  </>);
};