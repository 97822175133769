import React from 'react';
import { Outlet } from 'react-router-dom';

import GetUser from './GetUser';
import IsValidParceiroID from './IsValidParceiroID';
import ParceiroInRede from './ParceiroInRede';
import GetParceiro from './GetParceiro';
import RedirectPlano from './RedirectPlano';

/**
 * Ao criar um middleware, é necessário registrar ele aqui
 * Ele nunca deve gerar rerender, então memoize é necessário
 * ---
 * Sempre que exportar usar o padrão abaixo:
 * export default React.memo(Object.assign(NomeDoComponente, {displayName: 'NomeDoComponente'}), () => true);
 * ---
 * Caso queira passar props para o middleware, basta chamar ele como objeto ao invés de string na array de middlewares
 * {nomeDoMiddleware: {...props}}
 */

const Registered = {
  GetUser,
  IsValidParceiroID,
  ParceiroInRede,
  GetParceiro,
  RedirectPlano
}
  

const Middlewares = ({use, children}) => {

  /**
   * Objeto para guardar dados que é pra ser enviado para o componente 
   * middleware através da prop data para que ele possa consumir esses dados.
   * Todo middleware deve renderizar um children, se não os subsequentes não carregam!
   */
  let data = {};

  const components = use
    .filter(item => (typeof item === 'string' ? item : Object.keys(item)[0]) in Registered)
    // .reverse()
    .map(item => {
      if (typeof item === 'string') {
        data[item] = null;
        return Registered[item];
      } else {
        let key = Object.keys(item)[0];
        data[key] = item[key];
        return Registered[key];
      }
    });

  // Loop através do array de componentes e renderização em ordem
  const RenderComponents = ({ children }) => {
    return components.reduceRight((nestedComponents, Component) => {
      console.log('Middleware Loaded:', Component?.type?.displayName);
      let props = use?.[Component?.type?.displayName];
      props = { ...props, children: nestedComponents };
      return <Component {...props} />;
    }, children);
  };

  return <RenderComponents><Outlet /></RenderComponents>;
}
  

export default Middlewares;