import { useAuth } from '@Contexts/Auth';
import { ListaPlanos as Planos } from '@Services/Helpers';
import React from 'react';
import { Navigate, generatePath, useLocation, useParams } from 'react-router-dom';

interface ComponentProps {
  children?: React.ReactNode
}

const REGISTERED_DASHBOARD = {
  g: Planos.game,
  p: [...Planos.fidelidade, ...Planos.cielo],
}

/**
 * 
 */
const RedirectPlano = ({ children }: ComponentProps) => {
  //////////////////////////////////////////////////////////////////////////////////////
  // Hooks
  //////////////////////////////////////////////////////////////////////////////////////
  const { parceiro } = useAuth();
  const location = useLocation();
  const params: any = useParams();


  //////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////
  let currentDashboard = location.pathname
    .substring(1)
    .split('/')
    .splice(0, 2);

  let isValidDashboard = isNaN(Number(currentDashboard[1]));
  let isCorrectDashboard = REGISTERED_DASHBOARD[currentDashboard[1]]?.includes(parceiro?.tipo_plano);



  let redirectUrl = location.pathname
    .substring(1)
    .split('/')
    .slice(isValidDashboard ? 3 : 2)
    .filter(item => item !== '')
    .join('/');


  //////////////////////////////////////////////////////////////////////////////////////
  // Render
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {parceiro && (<>
      {isCorrectDashboard && children}
      {!isCorrectDashboard && (<>
        {Planos.game.includes(parceiro?.tipo_plano) && <Navigate to={generatePath(`/dashboard/g/:idParceiro/${redirectUrl}`, { ...params })} replace />}
        {[...Planos.fidelidade, ...Planos.cielo].includes(parceiro?.tipo_plano) && <Navigate to={generatePath(`/dashboard/p/:idParceiro/${redirectUrl}`, { ...params })} replace />}
      </>)}
    </>)}
  </>)
}

export default React.memo(Object.assign(RedirectPlano, { displayName: 'RedirectPlano' }), () => true);