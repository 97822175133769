import React from 'react';
import { set, get } from "lodash";

export const OutletContext = React.createContext({});

export const useOutletContext = () => {
  return React.useContext(OutletContext);
};


const INITIAL_STATE = {
  header: <></>,
}

interface OutletContextData {
  children: React.ReactNode;
}
const reducer = (state = INITIAL_STATE, action) => {
  const { action: act, context, data } = action;

  if (act === 'reset') {
    return INITIAL_STATE;
  }

  if (act === 'set' && context) {
    return set({...state}, context, data);
  }
  return state;
}



export const OutletProvider = ({ children }:OutletContextData) => {
  //////////////////////////////////////////////////////////////////////////////////////
  // Context
  //////////////////////////////////////////////////////////////////////////////////////
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE); // Reducer
  const trigger = (action, context, data) => dispatch({ action, context, data }); // Helper Reducer

  const contextHelpers:any = {
    setOutlet: (key, value) => trigger('set', key, value),
    getOutlet: (key) => get(state, key, <></>),
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (
    <OutletContext.Provider value={contextHelpers}>
      {children}
    </OutletContext.Provider>
  );
};