const RoutesParceiroPlanoGame = [
    {
        path: "dashboard/g",
        dashboard: "Parceiro/Game",
        prefix: "Meu Fidelizi",
        title: "Dashboard",
        children: [
            {
                path: ":idParceiro",
                abstract: true,
                children: [
                    {
                        path: "",
                        abstract: true,
                        title: 'Início',
                    },
                    {
                        path: "Consultar",
                        component: "/Padrao/Pontuar",
                        title: "Consultar Cliente",
                        description: "Acesse os dados cadastrais do seu cliente, confira e resgate prêmios ganhos.",
                        guides: [
                            {
                                type: 'article', // article, tour, video
                                text: 'Aprenda a resgatar prêmios',
                                id: 6457172
                            },
                            {
                                type: 'tour',
                                text: 'Resgate um prêmio',
                                id: 395207,
                            },
                            {
                                type: 'tour',
                                text: 'Cadastre um novo cliente',
                                id: 395193,
                            },
                        ],
                        menu: {
                            order: 0,
                            icon: "fa fa-user-magnifying-glass",
                            name: "Consultar Cliente",
                        },
                    },
                    {
                        path: "atendentes",
                        component: "/Padrao/Atendentes/Gerenciar",
                        title: 'Atendentes',
                        menu: {
                            order: 4,
                            icon: "fa fa-user-group-simple",
                            name: "Atendentes",
                        },
                        description: "Crie senhas de autorização para sua equipe e garanta análises de performance individual.",
                        guides: [
                            {
                                type: 'article', // article, tour, video
                                text: 'Veja como cadastrar senhas para os seus atendentes',
                                id: 6255694,
                            },
                            {
                                type: 'tour', // article, tour, video
                                text: 'Crie uma senha agora',
                                id: 393148,
                            },
                        ],
                    },
                    {
                        path: "metricas",
                        permissions: ['permissao_metricas'],
                        component: "/Padrao/Metricas",
                        menu: {
                            order: 3,
                            icon: "fa fa-chart-area",
                            name: "Métricas",
                        },
                        children: [
                            {
                                path: "graficos",
                                component: "/Graficos",
                                title: "Métricas → Gráficos",
                                menu: {
                                    name: "Graficos",
                                },
                                description: "Analise graficamente as métricas mais relevantes do seu Fidelizi e tenha insights poderosos.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba interpretar seus gráficos',
                                        id: 6475586,
                                    },
                                    {
                                        type: 'tour',
                                        text: 'Analise agora',
                                        id: 395388,
                                    },
                                ],
                            },
                            {
                                path: "nps",
                                component: "/RelatorioNps",
                                title: "Métricas → NPS",
                                menu: {
                                    name: "NPS",
                                },
                                description: "Saiba se seus clientes estão satisfeitos com seus produtos e serviços por meio do NPS score.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre o seu NPS',
                                        id: 6985017,
                                    },
                                ],
                            },
                        ]
                    },
                    {
                        path: "clientes",
                        component: "/Game/Clientes/Lista",
                        title: "Clientes → Lista de Clientes",
                        description: "Gerencie e consulte os dados dos seus clientes de maneira inteligente, rápida e otimizada.",
                        menu: {
                            order: 5,
                            icon: "fa fa-users",
                            name: "Clientes",
                        },
                        children: [
                            {
                                index: true,
                                path: "lista",
                                component: "/Lista",
                                title: "Clientes",
                                description: "Gerencie e consulte os dados dos seus clientes de maneira inteligente, rápida e otimizada.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Entenda as possibilidades da sua lista de clientes',
                                        id: 6310663,
                                    },
                                    {
                                        type: 'tour', // article, tour, video
                                        text: 'Use agora',
                                        id: 395842,
                                    },
                                ],
                            },
                            {
                                path: ":idCliente",
                                component: "/GerenciarCliente",
                                title: "Clientes → Gerenciar Cliente",
                                description: "Permita-se ter insights incríveis com os dados de persona e receita dos seus principais perfis de clientes.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre as métricas da sua base',
                                        id: 6319515,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "premios-brindes",
                        component: "/Padrao/Premios",
                        title: 'Prêmios',
                        menu: {
                            order: 6,
                            icon: "fa fa-trophy",
                            name: "Prêmios",
                        },
                        children: [
                            {
                                path: "gerenciar",
                                component: "/Gerenciar",
                                title: "Prêmios & Brindes → Gerenciar",
                                description: "Gerencie, edite ou crie prêmios incríveis para o seu programa de fidelidade!",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a editar seus prêmios e muito mais!',
                                        id: 6319541,
                                    },
                                ],
                                menu: {
                                    name: "Gerenciar",
                                },
                                children: [
                                    {
                                        path: "premios-do-programa",
                                        component: "/Tabs/PremiosDoPrograma",
                                        title: "Prêmios & Brindes → Gerenciar Prêmios do Programa",
                                    },
                                    {
                                        path: "brindes-da-roleta",
                                        component: "/Tabs/BrindesDaRoleta",
                                        title: "Prêmios & Brindes → Gerenciar Brindes da Roleta",
                                    },
                                    {
                                        path: "premios-de-campanha",
                                        component: "/Tabs/PremiosDeCampanha",
                                        title: "Prêmios & Brindes → Gerenciar Prêmios de Campanha",
                                    },
                                    {
                                        path: "premios-surpresa",
                                        component: "/Tabs/PremiosSurpresa",
                                        title: "Prêmios & Brindes → Gerenciar Prêmios Surpresa",
                                    },
                                    {
                                        path: "premios-de-jogos",
                                        component: "/Tabs/PremiosDeJogos",
                                        title: "Prêmios & Brindes → Gerenciar Prêmios de Jogos",
                                    },
                                ],
                            },
                            {
                                path: "relatorio",
                                component: "/Relatorio",
                                title: "Prêmios & Brindes → Relatório",
                                menu: {
                                    name: "Relatório",
                                },
                                description: "Saiba quais clientes estão resgatando prêmios no seu Fidelizi e confira os status de resgate.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Entenda as informações do seu relatório',
                                        id: 6319539,
                                    },
                                ],
                            },
                            {
                                path: "desempenho",
                                component: "/Desempenho",
                                title: "Prêmios & Brindes → Desempenho",
                                menu: {
                                    name: "Desempenho",
                                },
                                description: "Analise de forma quantitativa o percentual de resgate dos seus prêmios e saiba quais estão fazendo mais sucesso.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba usar o relatório de desempenho',
                                        id: 6319534,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "configuracoes",
                        component: "/Padrao/Configuracoes",
                        title: 'Configurações',
                        menu: {
                            order: 9,
                            icon: "fa fa-cog",
                            name: "Configurações",
                        },
                        children: [
                            {
                                path: "detalhes",
                                component: "/DetalhesEmpresa",
                                title: "Configurações → Dados do Estabelecimento",
                                description: "Atualize os principais dados cadastrais do seu negócio e garanta que seus clientes te encontrem sempre.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Entenda como atualizar os dados do seu negócio',
                                        id: 6319567,
                                    },
                                ],
                                menu: {
                                    name: "Dados do Estabelecimento",
                                },
                            },
                            {
                                path: "identidade-visual",
                                component: "/IdentidadeVisual",
                                title: "Configurações → Identidade Visual",
                                description: "Defina a identidade visual do seu negócio e torne o seu Fidelizi ainda mais atraente! ",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba como definir sua identidade visual',
                                        id: 6443352,
                                    },
                                ],
                                menu: {
                                    name: "Identidade Visual",
                                },
                            },
                            {
                                path: "Regulamento",
                                component: "/ProgramaFidelidade",
                                title: "Configurações → Regulamento",
                                description: "Consulte ou edite as normas do seu Fidelizi.",
                                menu: {
                                    name: "Regulamento",
                                },
                            },
                            {
                                path: "totem",
                                component: "/Totem",
                                title: "Configurações → Configurações do Totem",
                                description: "Gerencie os dados do seu Totem Fidelizi e crie a melhor experiência para o cadastro dos seus clientes.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a configurar o seu totem',
                                        id: 6319556,
                                    },
                                ],
                                menu: {
                                    name: "Totem",
                                },
                            },
                            {
                                path: "testadores",
                                component: "/Testadores",
                                title: "Configurações → Testadores ",
                                description: "Crie usuários teste no seu Fidelizi quando quiser!",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba usar o recurso testadores',
                                        id: 6319564,
                                    },
                                ],
                                menu: {
                                    name: "Testadores",
                                },
                            },
                            {
                                path: "banidos",
                                component: "/Banidos",
                                title: "Configurações → Banidos ",
                                description: "Impossibilte que um cliente participe do seu Fidelizi usando o recurso de banimento.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a banir um cliente',
                                        id: 6924586,
                                    },
                                ],
                                menu: {
                                    name: "Banidos",
                                },
                            },
                        ],
                    },
                    {
                        path: "financeiro",
                        component: "/Padrao/Financeiro",
                        title: "Financeiro",
                        description: "Consulte os dados financeiros do seu Fidelizi a qualquer momento e em qualquer lugar.",
                        guides: [
                            {
                                type: 'article', // article, tour, video
                                text: 'Saiba consultar os seus boletos',
                                id: 6420969,
                            },
                            {
                                type: 'article', // article, tour, video
                                text: 'Aprenda a desbloquear o seu Fidelizi',
                                id: 6420949,
                            },
                            {
                                type: 'article', // article, tour, video
                                text: 'Tire suas dúvidas sobre o financeiro',
                                id: 6420878,
                            },
                        ],
                        menu: {
                            order: 8,
                            icon: "fa fa-credit-card",
                            name: "Financeiro",
                            title: "Financeiro",
                        },
                    },
                    {
                        path: "ferramentas",
                        component: "/Padrao/Ferramentas",
                        title: 'Ferramentas',
                        menu: {
                            order: 7,
                            icon: "fa fa-wrench",
                            name: "Ferramentas",
                        },
                        children: [
                            {
                                path: "whatsapp",
                                component: "/WhatsApp",
                                title: "Ferramentas → WhatsApp",
                                menu: {
                                    name: "WhatsApp",
                                },
                                description: "Explore o maior canal de comunicação dos brasileiros e garanta muitos clientes na sua loja.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a enviar mensagens no WhatsApp para seus clientes.',
                                        id: 6319548,
                                    },
                                    // {
                                    //   type: 'tour', // article, tour, video
                                    //   text: 'Crie agora',
                                    //   id: 393130,
                                    // },
                                ],
                                children: [
                                    {
                                        path: '',
                                        title: "Ferramentas → WhatsApp",
                                        component: "/Lista",
                                    },
                                    {
                                        path: ":idCampanha/relatorio",
                                        component: "/Relatorio",
                                        title: "Ferramentas → WhatsApp → Disparos & Relatórios",
                                    },
                                    {
                                        path: ":idCampanha/editar",
                                        component: "/Editar",
                                        title: "Ferramentas → WhatsApp → Editar disparo",
                                    },
                                    {
                                        path: "novo",
                                        component: "/Editar",
                                        title: "Ferramentas → WhatsApp → Novo disparo",
                                    },
                                ]
                            },
                            {
                                path: "preco-fidelidade",
                                component: "/PrecoFidelidade",
                                title: "Preço Fidelidade",
                                description: "Incentive mais cadastros criando etiquetas com descontos exclusivos para clientes fidelizados.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba usar o Preço Fidelidade',
                                        id: 6785580,
                                    },
                                ],
                                menu: {
                                    name: "Preço Fidelidade",
                                },
                            },
                            {
                                path: "jogos",
                                component: "/Game",
                                title: "Ferramentas → Jogos",
                                description: "Um game personalizado do seu estabelecimento para conseguir mais clientes.",
                                menu: {
                                    name: "Jogos"
                                },
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'O que é, como funciona e como configurar o Deblockz.',
                                        id: 8218474,
                                    },
                                ],
                                beta: true,
                            },
                        ],
                    },
                    {
                        path: "indica",
                        component: "/Padrao/Indica",
                        title: "Indica",
                        description: "Indique parceiros e ganhe prêmios em dinheiro ou descontos de mensalidades no seu Fidelizi.",
                        guides: [
                            {
                                type: 'article', // article, tour, video
                                text: 'Saiba usar o Indica Fidelizi',
                                id: 6319574,
                            },
                        ],
                        menu: {
                            order: 10,
                            icon: "fa fa-hand-point-right",
                            name: "Indica",
                            title: "Indica",
                        },
                    },

                ],
            },
            {
                path: "*",
                title: "Não Encontrado",
                abstract: true,
            },
        ]
    }
];

export default RoutesParceiroPlanoGame;
