const RoutesRedePadrao = [
  {
    path: "rede",
    dashboard: "Rede",
    prefix: 'Meu Fidelizi',
    children: [
      {
        path: "",
        index: true,
        component: "/Estabelecimentos",
        title: "Meus estabelecimentos",
        description: "Gerencie seus estabelecimentos",
        menu: {
          icon: "fa fa-store",
          name: "Estabelecimentos",
        },
      },
    ]
  }
];

export default RoutesRedePadrao;
