import React from 'react';
import nProgress from 'nprogress';

const NProgress = () => {
  React.useEffect(() => {
      nProgress.start();

      return () => {
          nProgress.done();
      };
  });

  return <></>;
};

export default NProgress;