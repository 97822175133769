import Page404 from 'src/_pages/publico/Page404';
import React from 'react';
import { useParams } from 'react-router-dom';
  
interface ComponentProps {
  children?: React.ReactNode;
}
  
const IsValidParceiroID = ({children}:ComponentProps) => {
  //////////////////////////////////////////////////////////////////////////////////////
  // Hooks
  //////////////////////////////////////////////////////////////////////////////////////
  const { idParceiro } = useParams();

  //////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////
  const isValidID = isNaN(Number(idParceiro)) ? false : true;

  //////////////////////////////////////////////////////////////////////////////////////
  // Render
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {!isValidID && <Page404/>}
    {isValidID && <>{children}</>}
    
  </>)
}
  
export default React.memo(Object.assign(IsValidParceiroID, {displayName: 'IsValidParceiroID'}), () => true);