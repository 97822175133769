import React from 'react';
import styles from './styles.module.scss';
import FLoading from '../FLoading';
import { createPortal } from 'react-dom';
  
interface ComponentProps {
  children: React.ReactNode;
  className?: string;
}
  
const OverlayLoading = ({children, className}:ComponentProps) => {
  
  return (<>
    {createPortal(<>
    <div className={`animate fadeIn ${styles.overlayLoading} ${className || ''}`}>
      <FLoading className={styles.logo} color={styles['purple-1']}></FLoading>
      <h5 className='headline mb-0 mt-4'>Aguarde</h5>
      {children}
    </div>
    </>,document.body)}
  </>)
}
  
export default OverlayLoading;